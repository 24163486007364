import React from 'react';
import style from './style.css';
import IconInfo from 'cccisd-icons/info2';
import AdministerExample from './Administer_Example.png';
import StudentDashboardExample from './3_Student_Dashboard.jpg';
import AlertsExample from './5_Alerts_withoutNavHeader.jpg';
import MessagingExample from './4_Messaging_withoutNavHeader.png';

const MockupPage = props => {
    const getMockup = () => {
        let mockupImage;

        switch (props.mockupPage) {
            case 'Administer':
                mockupImage = AdministerExample;
                break;
            case 'StudentDashboard':
                mockupImage = StudentDashboardExample;
                break;
            case 'Alerts':
                mockupImage = AlertsExample;
                break;
            case 'Messaging':
                mockupImage = MessagingExample;
                break;
            default:
                // Handle the default case or provide a default image
                mockupImage = AdministerExample;
        }

        return mockupImage;
    };

    return (
        <div>
            <div className={style.infoText}>
                <IconInfo />{' '}
                <i>
                    Please note that this {props.mockupPage} page is a mockup. Full functionality will be available at a
                    later stage of the project.
                </i>
            </div>
            <div>
                <img src={getMockup()} style={{ maxWidth: '100%' }} alt="Example of Administer" />
            </div>
        </div>
    );
};

export default MockupPage;
